/**
 * Custom select menu
 *
 * Accepted data-types: [ownership]
 *
 * Usage:

	<div id="some_name" class="select-contain" data-method="inline||api" data-endpoint="/api/v2/">
		<input type="hidden" name="owner_type" val="user"> <!-- Used with data-type="ownership" -->
		<input type="hidden" name="owner_username"> <!--Used with data-type="ownership"-->

		<a href="#" class="select" data-type="ownership"> <!-- Set data-type if needing special Ajax calls (ownership, sites and affiliate are only ones currently handled) -->
			<img class="select__thumb" height="35" width="35" src="[image src]">
			<span class="select__title">Select...</span>
		</a>
		<div class="select-list">
			<div class="select-list__header">

			</div>
			<ul class="select-list__list"> <!--- this can be left blank (no li's) depending on the type set
				<li class="select-list__item">
					<a href="#" class="select-list__link">
						<img class="select-list__thumb" height="35" width="35" src="[image src]">
						<span class="select-list__title">A select item test</span>
					</a>
				</li>
			</ul>
			<div class="select-list__footer">

			</div>
		</div>
	</div>


	on li's, you have 3 data attributes:
		value: responsible for settings the first and second hidden inputs
		search: responsible for helping the search input filter, only one of these
		icon: responsible for settings the icon when selected. only 1 can be set.

	<li data-value-0="value1" data-value-1="value2" data-search="value1"></li>
 */

import $ from 'jquery';
import jwtAuth from 'helpers/jwt-auth';
import regex from 'helpers/regex';
import avatarTpl from 'templates/shared/avatars.hbs';
import siteAvatarTpl from 'templates/shared/site-avatars.hbs';
import accountTpl from 'templates/shared/select/account-result.hbs';
import siteTpl from 'templates/shared/select/site-result.hbs';
import focusNext from 'helpers/focus-next';
import 'helpers/monkey-patches';

let instance;

const elements = {
	trigger: '.select',
	element: '.select-list',
	item: '.select-list__item',
	closeTrigger: '.--close-list',
	input: '.select-list__input',
	button: '.select-list__button'
};

const bindEvents = function () {
	$(document)
		// Document click trigger
		.on('click', documentHandler.bind(instance))

		// Keep 'er open if clicked anywhere inside the element
		.on('click', elements.element, (e) => {
			e.stopPropagation();
		})

		// Click trigger
		.on('click', elements.trigger, triggerHandler.bind(instance))

		// Click close
		.on('click', elements.closeTrigger, closeHandler.bind(instance))

		// Select item click
		.on('click', elements.item, itemHandler.bind(instance))

		// Button click
		.on('click', elements.button, buttonHandler.bind(instance))

		// Keydown in input
		.on('keydown', elements.input, inputHandler.bind(instance));

	$(document).on('modal:open', () => {
		// Document click trigger inside modal content
		$('.modal')
			.on('click', documentHandler.bind(instance))
			.on('click', '.select-list__header input', (e) => {
				e.stopPropagation();
			});
	});
};

const cachedData = {};

// Close 'er down if clicking anywhere on the document
const documentHandler = function () {
	$('.select-contain').removeClass('--open --loading');
	$(elements.input).removeClass('--error');
};

const storeDefaults = function () {
	const $resettableLists = $('.select-contain[data-resettable]');

	if ($resettableLists.length === 0) { return; }

	$resettableLists.each(function () {
		const $selectContain = $(this);

		if ($selectContain.data('default')) { return; }

		const $selectTitle = $selectContain.find('.select__title');

		$selectContain.data('default', $selectTitle.html());
	});
};

// Handle opening/closing when clicking trigger
const triggerHandler = function (e) {
	const $this = $(e.currentTarget);
	const container = $this.parent('.select-contain');
	const closestMenu = container.find('.select-list');
	const closestList = $(closestMenu).find('.select-list__list');
	const config = container.data();

	let listObject;

	e.preventDefault();
	e.stopPropagation();

	// close other select menus
	$('.select-contain').not(e.currentTarget).removeClass('--open --loading');

	if (container.hasClass('--open') || container.hasClass('--loading') || container.hasClass('--disabled')) {
		// close all select menus
		$('.select-contain').removeClass('--open --loading');
	} else {
		// open the select menu
		container.addClass('--open');
	}

	if (config.method === 'inline') {
		// Load from inline data
		if ($this.data('options')) {
			listObject = instance.getInlineData($this.data('options'), container.data('disabled'));
		}
	} else if (config.method === 'api') {
		// Load from an API or load from cache
		listObject = this.getData(config.endpoint, container, container.data('disabled'));
	}

	if (listObject !== false) {
		this.buildMenu(listObject, container);
	}

	this.filterSelect(closestList);

	$(document).trigger('select-list:open');
};

// Handle what happens when you click an item in the select menu
const itemHandler = function (e) {
	const $this = $(e.currentTarget);

	e.preventDefault();
	e.stopPropagation();

	// Remove error class of input
	$(elements.input).removeClass('--error');

	if ((!$this.hasClass('--group_title')) && !$this.hasClass('--disabled')) {
		this.updateSelect(e.currentTarget);
	}
};

// Handle what happens when you click an item with a close class
const closeHandler = function (e) {
	const $container = $(e.currentTarget).parents('.select-contain');
	$container.removeClass('--open');
};

// Handle what happens when you click an actual button
// This is also fired when the enter key is pressed
const buttonHandler = function (e) {
	e.preventDefault();

	const isEmail = $(e.currentTarget).data('type') === 'email' ? true : false;
	const $input = $(e.currentTarget).parents('.select-list__footer').find('.select-list__input');

	if ($input.val()) {
		if (isEmail) {
			if (regex.email($input.val())) {
				this.updateSelect(e.currentTarget);
			} else {
				$input.addClass('--error');
				$('<label id="email-label" class="error">Please enter an email address.</label>').insertAfter($input);
				$(elements.button).attr('disabled', true);
			}
		} else {
			this.updateSelect(e.currentTarget);
		}
	} else {
		$(elements.button).attr('disabled', true);
		$input.addClass('--error');
	}
};

// Watch if the input is changing so we can bring back the button
const inputHandler = function (e) {
	const $parent = $(e.currentTarget).parents('.select-list__footer');

	$(elements.button).removeAttr('disabled');
	$('#email-label').remove();

	// Remove error class when typing
	$(e.currentTarget).removeClass('--error');

	// Only do this if there's already text saved
	if ($parent.hasClass('--active')) {
		$parent.removeClass('--active');
	}
};

export default {

	token: '',

	// Kick things off
	init () {
		instance = this;

		this.$scope = $(document);

		bindEvents();
		storeDefaults();
	},

	// TODO: Currently unused, will contain the available items.
	// This is going to be the observable behavior for tests,
	// and should be publicly available
	items: [],

	// TODO: Currently unused, will contain the disabled items
	// This is going to be the observable behavior,
	// and should be publicly available
	disabled: [],

	// Make request to the element to get the data
	getInlineData (optionsRaw, disabledRaw) {
		const data = JSON.parse(atob(optionsRaw));
		const disabled = (disabledRaw && disabledRaw !== '') ? JSON.parse(atob(disabledRaw)) : [];

		return {
			items: data,
			disabledItems: disabled
		};
	},

	// Make request to API or JS cache and get data
	getData (endpoint, container, disabledRaw) {
		let items = [];
		let disabled = [];

		if (typeof cachedData[endpoint] !== 'undefined') {
			return {
				items: cachedData[endpoint],
				disabledItems: disabled
			};
		}

		const token = document.body.dataset.token;
		if (!token) {
			return { items: [], disabledItems: [] };
		}

		this.makeRequest({ endpoint, token })
			.then((data) => {
				// cache data
				cachedData[endpoint] = data;
				items = data;
			})
			.finally(() => {
				if (typeof disabledRaw !== 'undefined' && disabledRaw.length) {
					disabled = (disabledRaw) ? JSON.parse(atob(disabledRaw)) : disabled;
				}

				items = {
					items,
					disabledItems: disabled
				};

				this.buildMenu(items, container);
			});

		return {
			items,
			disabledItems: disabled
		};
	},

	makeRequest ({ endpoint, token }) {
		jwtAuth.init();

		return new Promise((resolve, reject) => {
			jwtAuth.generateToken().then((token) => {
				$.ajaxSetup({
					headers: token
				});

				$.ajax({
					method: 'get',
					url: endpoint,
					dataType: 'json',
					success: (data) => resolve(data),
					error: (xhr, txtStatus, errorStatus) => reject(errorStatus)
				});
			});
		});
	},

	// Build the actual menu
	buildMenu (listObject, container) {
		const $menu = $(container).find('.select-list__list');

		if (typeof listObject !== 'undefined' && $menu.find('li').length === 0) {
			const length = listObject.items.length;

			for (let i = 0; i < length; i++) {

				if (container.data('template') === 'account') {
					// Users
					$menu.append(this.accountContent(listObject.items[i], i, listObject.disabledItems));

				} else if (container.data('template') === 'site') {
					// All else
					$menu.append(this.siteContent(listObject.items[i], i, listObject.disabledItems));
				} else {
					// All else
					$menu.append(this.defaultContent(listObject.items[i], i, listObject.disabledItems));
				}

				// Remove spinner if it's last one
				if (i === (length - 1)) {
					container.removeClass('--loading');
				}
			}
		}

		$('.select-list__item').on('focus', () => {
			focusNext.now();
		});
	},

	checkSvg () {
		return `<span class="icon select-list__check" color="blue">${require(`!svg-inline-loader?removeSVGTagAttrs=false!svgPath/checkmark.svg`)}</span>`;
	},

	// Owner return
	accountContent (account, index, disabled) {
		const flattenedDisabled = _.flatten(disabled);
		const disabledClass = ($.inArray(account.username, flattenedDisabled) !== -1) ? '--disabled' : '';
		const orgClass = (account.type === 'Organization') ? ' --org' : '';

		const avatar = avatarTpl({
			account,
			size: 'tiny',
			classes: `select-list__thumb${orgClass}`,
			dimensions: 35,
			letter: account.initials.substring(0, 1)
		});

		const accountItem = accountTpl({
			account,
			avatar,
			svg: this.checkSvg,
			index,
			disabled: disabledClass
		});

		return accountItem;
	},

	// Site return
	siteContent (site) {
		const avatar = siteAvatarTpl({
			site,
			size: 'tiny',
			classes: 'select-list__thumb',
			dimensions: 35
		});

		const siteTemplate = siteTpl({
			site,
			avatar,
			svg: this.checkSvg
		});

		return siteTemplate;
	},

	// Fallback template
	defaultContent (item) {
		const title = (item.length === 2) ? item[0] : item.toString();
		const value = (item.length === 2) ? item[1] : item.toString();
		const svg = this.checkSvg();

		let finalHtml;

		if (typeof value === 'object') {
			finalHtml = $(`<li class="select-list__item  --group_title" data-value="${value}" data-search="${title.toLowerCase()}"><a href="#" class="select-list__link"><span class="select-list__title">${title}</span>${svg}</a></li>`).prop('outerHTML');

			for (const child of value) {
				const childItem = $(`<li class="select-list__item" data-value="${child[1]}" data-name="${child[0].toLowerCase()}"><a href="#" class="select-list__link"><span class="select-list__title">${child[0]}</span>${svg}</a></li>`).prop('outerHTML');

				finalHtml += childItem;
			}

			return finalHtml;
		}

		if (item[1] === '-') {
			return $('<li class="select-list__separator"></li>');
		}

		return $(`<li class="select-list__item" data-value="${value}" data-search="${title.toLowerCase()}"><a href="#" class="select-list__link"><span class="select-list__title">${title}</span>${svg}</a></li>`);
	},

	emitEvent(item, payload) {
		this.$scope.trigger(item, payload);
		this.$scope.get(0).dispatchEvent(new CustomEvent(item, { detail: payload }));
	},

	updateSelect (item) {
		const $item = $(item);
		const $container = $item.parents('.select-contain');
		const attributes = $item.data();
		const values = [];
		const $inputs = $container.find('input[type="hidden"]');
		const listEvents = ['select-list:change'];
		const $currentTitle = $container.find('.select__title');
		const $currentPunyCode = $container.find('.select__punycode');
		const $currentThumb = $container.find('.select__thumb');
		const thumb = $item.find('.select-list__thumb');
		const $thumbToReplace = thumb.clone().removeClass('select-list__thumb').addClass('select__thumb');
		const isMultiselect = $container.hasClass('--multiselect');
		const $input = $item.parents('.select-list__footer').find('input');
		const defaultTitle = $container.data('default');
		const defaultPunyCode = '';

		let $items;
		let name = $item.find('.select-list__title').html() || $input.val();
		let punyCode = $item.find('.select-list__punycode').html() || '';
		let $inputCollection;
		let disabling;
		let selected;

		if (isMultiselect) {
			const $input = $container.find('input[type="hidden"]:first').clone();

			$items = $container.find('.select-list__item');
			$inputCollection = $container.find('input[type="hidden"].--cloned');
			disabling = $item.hasClass('--active');
			selected = [];

			// clear cloned values out of the DOM
			$inputCollection.remove();

			// Toggle the class to this item
			if (disabling) {
				$(item).removeClass('--active');
			} else {
				$(item).addClass('--active');
			}

			$container.find($items).each((index, item) => {
				if ($(item).hasClass('--active')) {
					const $el = $input.clone().addClass('--cloned').val($(item).data('value'));

					$container.prepend($el);

					// TODO: Will fire for each input... so quite often.
					if ($input.attr('data-event_namespace')) {
						listEvents.push(`select-list.${$input.attr('data-event_namespace')}:change`);
					}

					selected.push({
						title: $(item).find('.select-list__title').text(),
						thumb: $(item).find('.select-list__thumb'),
						value: $(item).data('value')
					});
				}
			});

			name = this.getName(selected, $container);

			// Replace select text
			$currentTitle.text(this.getCurrentTitle(defaultTitle, name));

			this.updateMultiSelectTable(item, selected);

			$.each(listEvents, function () {
				$(document).trigger(this, {
					multiselect: isMultiselect,
					attributes,
					values: selected
				});
			});
		} else {
			// Add the active class to this item
			$container.find(elements.item).removeClass('--active');
			$item.addClass('--active');

			// Make sure no footers are active
			$('.select-list__footer').removeClass('--active');

			// close all select menus
			$container.removeClass('--open');

			// Replace select text
			if (defaultTitle) {
				$currentTitle.text(defaultTitle);
			} else {
				$currentTitle.html(name);
			}

			// Replace punycode text
			if (defaultPunyCode) {
				$currentPunyCode.text(defaultPunyCode);
			} else {
				$currentPunyCode.text(punyCode);
			}

			// Replace image url
			$currentThumb.replaceWith($thumbToReplace);

			$.each(attributes, (index, item) => {
				if (index === 'value[0]' || index === 'value-0') {
					values[0] = item;
				} else if (index === 'value[1]' || index === 'value-1') {
					values[1] = item;
				} else if (index.match('^value')) {
					values.push(item);
				}
			});

			values.reverse();

			$inputs.each(function (i) {
				$(this).val(values[i]);

				if ($(this).attr('data-event_namespace')) {
					listEvents.push(`select-list.${$(this).attr('data-event_namespace')}:change`);
				}

				listEvents.push(`select-list.${$(this).attr('id')}:change`);

				this.dispatchEvent(new Event('change'));
			});

			// If an input is where this event comes from and not a normal list click
			if ($input.length) {
				$(item).parents('.select-list__footer').addClass('--active');

				// Update hidden field value with input value
				$inputs.val(name);
			} else {
				$('.select-list__input').val('');
			}

			$container.find('.select').focus();

			$.each(listEvents, (index, item) => {
				this.emitEvent(item, {
					multiselect: isMultiselect,
					attributes,
					values
				});
			});
		}
	},

	getName (selected, container) {
		let name;

		if (selected.length > 1) {
			name = `${selected.length} selected`;
		} else if (selected.length === 1) {
			name = selected[0].title;
		} else if (selected.length === 0) {
			name = container.data('placeholder');
		}

		return name;
	},

	getCurrentTitle (defaultTitle, name) {
		let title = name;

		if (defaultTitle) {
			title = defaultTitle
		}

		return title;
	},

	updateMultiSelectTable (item, selectedArray) {
		const $items = $(item).parent().find('.select-list__item');
		const $tableFields = $('tbody', '.select-list__table');
		const cancelSVG = `<span class="icon">${require(`!svg-inline-loader?removeSVGTagAttrs=false!svgPath/close_small.svg`)}</span>`;

		$tableFields.empty();

		$(selectedArray).each((index, object) => {
			$tableFields.append(`<tr><td class="--has-avatar" width="46">${$(object.thumb).prop('outerHTML')}</td><td align="left">${object.title}</td><td align="right"><a href="#remove" class="button --link --error" data-value="${object.value}">${cancelSVG}</a></td></tr>`);
		});

		$tableFields.find('a[href="#remove"]').on('click', (e) => {
			e.preventDefault();
			e.stopPropagation();

			const value = $(this).data('value');

			$items.filter(`[data-value="${value}"]`).trigger('click');
		});
	},

	// Add the following haml underneath the .select-list__header so you can filter the options.
	// %input{ type: "search", id: field_id, placeholder: "Search", autofocus: true }
	filterSelect (menu) {
		const filterer = menu.parent().find('input[type="search"]');

		if (filterer.length) {
			this.filterSelectKeyDown(filterer, menu);
			this.filterSelectChange(filterer);
		}
	},

	filterSelectKeyDown (filterer, menu) {
		let currentValue;
		let $theOptions;

		filterer.on('keyup.select-list-filter', function (e) {
			if (e.keyCode !== 16) {
				e.preventDefault();

				currentValue = $(this).val().toLowerCase();
				$theOptions = $('li', menu);

				// Hide everything at default
				$theOptions.hide();

				if (currentValue !== '') {
					currentValue = currentValue.replaceAll('"', '');

					// This will do a fuzzy search on the options
					menu.find(`li[data-search*="${currentValue}"]`).show();
					menu.find('li.--group_title').show();
				} else {
					// shows everything if the filterer is empty
					$theOptions.show();
				}
			}
		});
	},

	filterSelectChange (filterer) {
		$(document).on('select-list:change', (event, data) => {
			if (!data.multiselect) {
				filterer.val('');
				filterer.trigger('keyup');
				filterer.off('keyup.select-list-filter');
				$(document).off('select-list:change');
			}
		});
	}
};
